<template>
    <GrayBoxLoader cardRow v-if="loading" />
    <NebulaGridRow v-else-if="hasResources" gutter>
        <NebulaGridBlock
            v-for="(resource, idx) in resources"
            :key="`asset-${idx}`"
            :columns="3"
            :mdSize="4"
            :smSize="4"
        >
            <ResourceCard
                :asset="resource"
                :loading="resource.loading"
            />
        </NebulaGridBlock>
        <NebulaGridBlock v-if="paginate" :columns="12">
            <NebulaButton
                v-if="resourceQueue.length > 0"
                class="resource-grid__load-more-button"
                text="Load more"
                type="flat"
                @click="loadMore"
            />
        </NebulaGridBlock>
    </NebulaGridRow>
    <div v-else>
        <slot name="emptyState">
            <EmptyState
                v-if="emptyState"
                v-bind="emptyState"
            />
        </slot>
    </div>
</template>

<script>
import { NebulaButton, NebulaGrid } from '@discoveryedu/nebula-components';
import EmptyState from '@/components/shared/layout/EmptyState.vue';
import ResourceCard from '@/components/shared/cards/ResourceCard.vue';
import {
    getSemanticKey,
    allOrgContent,
    assetRandomOrder,
    assetOrder,
    randomOrder,
    getAssetOrgId,
    singleOrgContent,
    resourceList,
} from '@/utils/data/resources';
import createPayload from '@/mixins/data/createPayload';
import GrayBoxLoader from '@/components/shared/Loader/GrayBox.vue';
import { baseAssetCategory } from '@/constants';

const assetList = require('@/data/assets/org_content.json');

export default {
    name: 'ResourcesGrid',
    components: {
        NebulaButton,
        EmptyState,
        GrayBoxLoader,
        NebulaGridRow: NebulaGrid.NebulaGridRow,
        NebulaGridBlock: NebulaGrid.NebulaGridBlock,
        ResourceCard,
    },
    mixins: [createPayload],
    computed: {
        assetKey() {
            if (this.subcategory) {
                return `${this.topic}_assets_${this.subcategory}`;
            }
            return `${this.topic}_assets`;
        },
        page() {
            return this.$store.state.page;
        },
        resourceQueue() {
            return this.$store.state.app.queue[this.assetKey] || [];
        },
        show() {
            return Object.keys(this.resources).length > 0;
        },
        hasResources() {
            return !!this.resources && Object.keys(this.resources).length > 0;
        },
        pageLoading() {
            return this.$store.state.status.page.loading;
        },
        spotlight() {
            return this.$store.state.app.spotlight;
        },
    },
    props: {
        topic: {
            type: String,
            required: true,
        },
        organizationId: String,
        subcategory: String,
        title: String,
        max: {
            type: Number,
            default: 4,
        },
        emptyState: {
            type: Object,
        },
        viewAll: Object,
        paginate: Boolean,
    },
    data() {
        return {
            paginatedMax: this.max,
            resources: [],
            loading: true,
        };
    },
    methods: {
        getResourceList(fromQueue) {
            let { subcategory } = this;
            let { topic } = this;
            let spotlightKey;
            if (this.spotlight) {
                spotlightKey = this.spotlight.key;
            }

            let resources = [];

            if (fromQueue) {
                return this.$store.state.app.queue[this.assetKey];
            }

            if (topic === 'recommended') {
                // TODO: ideally this is actually a list of recommended assets
                return assetRandomOrder(allOrgContent(spotlightKey), this.spotlight);
            }

            if (topic === 'all') {
                return assetOrder(allOrgContent(spotlightKey), this.spotlight);
            }

            // allows an org id to be passed in for topic
            if (getSemanticKey(topic)) {
                topic = getSemanticKey(topic);
            }

            if (!subcategory) {
                subcategory = baseAssetCategory;
            }

            const baseObj = assetList[topic];
            if (!baseObj || !baseObj[subcategory]) {
                // if no general content, fetch all subcategories together
                return randomOrder(singleOrgContent(topic), topic);
            }
            resources = randomOrder(resourceList(topic, subcategory));

            return resources;
        },
        async fetchAssets(fromQueue) {
            const resources = this.getResourceList(fromQueue);
            // // if feature flag enabled, and org resources is truthy
            if (resources && resources.length > 0) {
                if (!fromQueue) {
                    this.$store.dispatch('updateLoading', { key: 'page', status: true });
                }
                const orgIds = [];
                resources.forEach((each) => {
                    const associatedOrg = getAssetOrgId(each);
                    if (associatedOrg && !orgIds.includes(associatedOrg)) {
                        orgIds.push(associatedOrg);
                    }
                });

                // optimization here, first fetch organization info so it's already available and doesn't need to be fetched multiple times
                await Promise.all(
                    orgIds.map(async (organizationId) => {
                        const orgPayload = await this.createPayload({ organizationId });
                        return this.$store.dispatch('viewOrganization', orgPayload);
                    }),
                );
                this.$store.dispatch('updateLoading', { key: 'page', status: false });

                this.loading = false;
                await Promise.all(
                    resources.map(async (assetId) => {
                        const payload = await this.createPayload({
                            assetId,
                            type: this.topic,
                            subcategory: this.subcategory,
                            max: this.paginatedMax,
                        });
                        await this.$store.dispatch('getResource', payload);
                    }),
                );
                this.$emit('fetch-complete');
            } else {
                this.loading = false;
            }
        },
        loadMore() {
            this.paginatedMax += this.max;

            // argument tells the fetchAssets function to pull resources from the queue
            this.fetchAssets(true);
        },
    },
    async mounted() {
        await this.fetchAssets();

        if (this.resources !== this.$store.state.page[this.assetKey]) {
            this.resources = this.$store.state.page[this.assetKey];
        }
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            const resourceMutations = ['UPDATE_ASSET', 'REMOVE_ASSET'];
            if (resourceMutations.includes(mutation.type)) {
                this.resources = state.page[this.assetKey];
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<style lang="stylus">
.resource-grid {
    &__load-more-button {
        justify-content: center;
        margin-block-start: $nebula-space-2x;
        text-align: center;
        width: 100%;
    }
}
</style>
