<template>
    <component
        :is="sectionElement"
        :class="className"
        :style="cssProps"
    >
        <slot name="sectionTitle">
            <NebulaTitleBlock
                v-if="title"
                class="page-section__title--section"
                :title="title"
                type="section"
            >
                <NebulaButton
                    v-if="viewAll.show"
                    class="careers-page-section__button"
                    :link="viewAll.link"
                    :text="viewAll.text || 'View All'"
                    type="flat"
                    size="s"
                    routerLink
                />
            </NebulaTitleBlock>
        </slot>
        <div class="page-section__content">
            <slot />
        </div>
    </component>
</template>

<script>
import { NebulaThemingUtils, NebulaTitleBlock, NebulaButton } from '@discoveryedu/nebula-components';

export default {
    name: 'PageSection',
    components: {
        NebulaTitleBlock,
        NebulaButton,
    },
    mixins: [NebulaThemingUtils],
    data() {
        return {
            themePrefix: 'careers',
        };
    },
    props: {
        title: {
            type: String,
        },
        type: {
            type: String,
            default: 'flat',
            validator(val) {
                // if other types get added be sure to add them here
                const types = ['raised', 'content-raised', 'flat', 'emphasized'];
                return types.includes(val);
            },
        },
        padded: {
            type: Boolean,
            default: false,
        },
        overflow: {
            type: Boolean,
            default: false,
        },
        viewAll: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {
        className() {
            return [
                'page-section',
                `page-section--${this.type}`,
                {
                    'page-section--padded': this.padded,
                },
                {
                    'page-section--overflow': this.overflow,
                },
                {
                    'page-section--emphasized': this.emphasized,
                },
            ];
        },
        sectionElement() {
            if (this.$slots.sectionTitle) {
                return 'section';
            }

            return 'div';
        },
    },
};
</script>

<style lang='stylus'>
:root {
    --careers-section-background-color: var(--nebula-color-platform-interface-100);
    --careers-section-border: none;
    --careers-section-box-shadow: $nebula-shadow-100;
    --careers-section-padding: $nebula-space-3x;
}

.page-section {
    --nebula-card-max-width: 100%;
    background-color: var(--careers-section-background-color);
    display: flex;
    flex-direction: column;
    gap: $nebula-space-2x;
    margin-block-start: $nebula-space-5x;
    margin-inline: auto;
    position: relative;
    width: 100%;

    &--overflow {
        overflow: hidden;
    }

    &--padded {
        padding: var(--careers-section-padding);
    }

    &--raised {
        card-base();
        --careers-section-background-color: $nebula-color-platform-white;
        border: var(--careers-section-border);
    }

    &--emphasized {
        card-base();
        background-color: $nebula-color-platform-interface-100;
        border: 1px solid $nebula-color-platform-interface-400;
    }

    &--content-raised {
        --careers-section-background-color: transparent;

        .page-section__content {
            card-base();
            background-color: $nebula-color-platform-white;
            border: var(--careers-section-border);
            padding: var(--careers-section-padding);
        }
    }

    &--flat {
        --careers-section-background-color: transparent;

        .page-section__content {
            background-color: var(--careers-section-background-color);
        }
    }

    &__title--section {
        --nebula-font-size-header-level-5: $nebula-font-size-card-title;
    }
}
</style>
