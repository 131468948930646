<template>
    <component
        :is="orgComponent"
        :to="link"
        :class="['organization-name', {
            'organization-name--heavy': heavy,
        }]"
    >
        {{ organization.name }}
    </component>
</template>

<script>
import { educatorRoles } from '@/constants';

export default {
    name: 'OrgName',
    props: {
        organization: Object,
        heavy: Boolean,
        isLink: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        orgComponent() {
            if (this.isMember && this.isLink) {
                return 'router-link';
            }
            return 'div';
        },
        orgId() {
            const { organization } = this;
            return organization.organizationId || organization.organization_id;
        },
        isMember() {
            if (educatorRoles.includes(this.$store.getters.appMode)) {
                return true;
            }
            const orgIds = this.$store.getters.userOrganizations.map((each) => each.organizationId);

            return !!orgIds.includes(this.orgId);
        },
        link() {
            return this.isMember && this.isLink ? `/organization/${this.orgId}` : '';
        },
    },
};
</script>

<style lang="stylus">
.organization-name {
    color: $nebula-color-platform-neutral-700;
    font-size: $nebula-font-size-caption;

    &--heavy {
        font-weight: 600;
    }
    &--light {
        font-weight: 400;
    }
}
</style>
